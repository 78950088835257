import { Button, Dialog, Stack, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { DEFAULT_LANGUAGE, DEFAULT_TIMEZONE } from '../../common/constants'
import LanguageSetting from './LanguageSetting'
import TimezoneSetting from './TimezoneSetting'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from 'ui'

const renderTitle = (enableMultiLng: boolean, enableTimezone: boolean) => {
  if (enableMultiLng && enableTimezone) {
    return 'Language and time zone preferences'
  }

  if (enableMultiLng) {
    return 'Language preferences'
  }

  if (enableTimezone) {
    return 'Time zone setting'
  }
}

interface Props {
  enableMultiLng: boolean
  enableTimezone: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit?: () => void
}

function PreferenceModal({
  enableMultiLng,
  enableTimezone,
  isOpen,
  onClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  const showSubtitle = enableMultiLng && enableTimezone

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || DEFAULT_LANGUAGE,
  )
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    localStorage.getItem('selectedTimezone') || DEFAULT_TIMEZONE,
  )

  // Using Local Storage, onSubmit is optional for possible future needs
  const handleConfirm = () => {
    if (enableTimezone) {
      localStorage.setItem('selectedTimezone', selectedTimezone)
    }

    if (enableMultiLng) {
      localStorage.setItem('selectedLanguage', selectedLanguage)
    }

    onClose()
    window.location.reload()
  }

  return (
    <Dialog
      sx={{ color: 'rgba(0, 0, 0, 0.30)' }}
      className="PreferenceModal"
      open={isOpen}
    >
      <Container gap="8px">
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          padding="16px 24px 8px 24px"
        >
          {' '}
          <Typography variant="h5">
            {t(`${renderTitle(enableMultiLng, enableTimezone)}`)}
          </Typography>
          <Button onClick={onClose}>
            <CloseIcon width="20" height="20" />
          </Button>
        </Stack>
        <Stack padding="0px 24px 16px 24px" gap="20px">
          {enableMultiLng && (
            <LanguageSetting
              showSubtitle={showSubtitle}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}
          {enableTimezone && (
            <TimezoneSetting
              showSubtitle={showSubtitle}
              selectedTimezone={selectedTimezone}
              setSelectedTimezone={setSelectedTimezone}
            />
          )}
        </Stack>
        <Stack
          flexDirection="row"
          padding="8px 16px 16px 16px"
          gap="8px"
          justifyContent="flex-end"
        >
          <ModalButton
            disableElevation
            disableTouchRipple
            variant="text"
            onClick={onClose}
          >
            {t('Cancel')}
          </ModalButton>
          <ModalButton
            disableElevation
            disableTouchRipple
            variant="contained"
            color="secondary"
            onClick={() => {
              handleConfirm()
            }}
          >
            {t('Save')}
          </ModalButton>
        </Stack>
      </Container>
    </Dialog>
  )
}

export default PreferenceModal

const Container = styled(Stack)`
  width: 600px;
  border-radius: 8px;
`

const ModalButton = styled(Button)`
  width: 79px;
  padding: 12px 18px 12px 18px;
  gap: 8px;
`
