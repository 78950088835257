import { constructPermMapWithPolicyOverride, getPermission } from './permission'
import { AxiosResponse } from 'axios'

export interface UserEnvironment {
  id: number
  name: string
  ops: string[]
}

export interface UserPortal {
  id: number
  name: string
  portalIconUrl: string
  portalUrl: string
}

export interface User {
  email: string
  name: string
  orgId: number
  operators: Operator[]
  sessionExpiresAt: number
  environments: UserEnvironment[]
  portals: UserPortal[]
  roles: string[]
  perms: Map<string, Set<string>>
}

export interface OperatorConfig {
  id: number
  internalEnvId: number
  internalEnvName: string
  internalId: number
  name: string
  orgId: number
  orgName: string
}

// Application type, todo, delete the duplicate ones defined in other places
export interface Operator {
  operator: string
  name: string
}

export interface ConfigResponse {
  // Was told to use operators_by_env instead of operators, not clear why
  operators: Record<string, OperatorConfig>
  operators_by_env: Record<string, OperatorConfig>
  orgs: Record<number, OrgConfig[]>
  portals: Record<number, PortalConfig>
  roles: string[]
}
export interface UserInfoResponse {
  email: string
  environments: UserEnvironment[]
  name: string
  orgId: number
  sessionExpiresAt: number
  perms: any[]
  policies: any
}

export interface OrgConfig {
  internalEnvId: number
  internalEnvName: string
  internalId: number
  name: string
  orgId: number
  orgName: string
}

export interface PortalConfig {
  id: number
  name: string
  productUrl: string
  productIconUrl: string
}

export enum Env {
  QAT = 'QAT',
  PREPROD = 'PREPROD',
  PROD = 'PROD',
  STG = 'STG',
  INTG = 'INTG',
}

export interface PlatformAppProps {
  userName: string
  logOutURL: string
  portalId: number | string
  portals: UserPortal[]
  nonce?: string
  env?: Env
  enableTimezone?: boolean
  enableMultiLng?: boolean
  newFont?: boolean
}

const IAM_ENV = {
  QAT: 'https://qat-hub.dev.g7y.io',
  PREPROD: 'https://preprod-bop.geocomply.com',
  PROD: 'https://bop.geocomply.com',
  STG: 'http://intg-hub.geocomply.net',
}

function mapPortals(portals: Record<string, PortalConfig>): UserPortal[] {
  const result: UserPortal[] = []
  for (const portalId of Object.keys(portals)) {
    const portal = portals[portalId]
    result.push({
      id: portal.id,
      name: portal.name,
      portalIconUrl: portal.productIconUrl,
      portalUrl: portal.productUrl,
    })
  }
  return result
}

declare global {
  interface Window {
    UBO_CONFIG: ConfigResponse
    UBO_USER: UserInfoResponse
    UBO_UAM_LANDING_PAGE: string
  }
}

function getPortal(id: number): UserPortal {
  const config = window.UBO_CONFIG
  const portal = config.portals[id]
  return {
    id: portal.id,
    name: portal.name,
    portalIconUrl: portal.productIconUrl,
    portalUrl: portal.productUrl,
  }
}

function getCurrentUser(): User {
  const user = window.UBO_USER
  // if not user, return empty user
  if (!user) {
    return {
      email: '',
      name: '',
      orgId: 0,
      operators: [],
      environments: [],
      sessionExpiresAt: 0,
      portals: [],
      roles: [],
      perms: new Map(),
    }
  }
  const config = window.UBO_CONFIG as ConfigResponse
  const portals = mapPortals(config.portals)

  const operatorAccess = Object.values(config.operators_by_env).map(
    (operator) => {
      return {
        // internal id is the operator id
        operator: `${operator.internalId}-${operator.internalEnvName}`,
        name: operator.name,
      }
    },
  )

  return {
    email: user.email,
    name: user.name,
    orgId: user.orgId,
    operators: operatorAccess,
    environments: user.environments,
    sessionExpiresAt: user.sessionExpiresAt,
    portals,
    roles: config.roles,
    perms: constructPermMapWithPolicyOverride(user.perms, user.policies ?? []),
  }
}

function getConfig(): ConfigResponse {
  return window.UBO_CONFIG as ConfigResponse
}

const PORTAL_ID: Record<string, number> = {
  UAM: 1,
  UBO: 2,
}

function handleFetchResponse(res: Response) {
  if (res.status === 401) {
    window.location.replace(
      `/auth/v1/login?redirect_uri=${encodeURIComponent(window.location.href)}`,
    )
    throw new Error('Unauthorized')
  }
  if (res.status !== 200) {
    window.location.replace('/error/500')
    throw new Error('Server error')
  }
  return res.json()
}

function parseVersion(res: string): string {
  const imports = JSON.parse(res)
  const defaultVersion = 'v1.1.2'
  if (!imports) {
    return defaultVersion
  }
  const importmap = imports.imports['@geocomply/explorer-app'] // version of explorer
  if (!importmap) {
    return defaultVersion
  }
  const vers = importmap.split('/')
  if (!vers || vers.length !== 5) {
    return defaultVersion
  }
  const fullVer = vers[3] // 1.2.12-36
  if (!fullVer) {
    return defaultVersion
  }
  const [ver, build] = fullVer.split('-') // remove the build number 1.2.12
  return ver === 'latest' ? ver : `v${ver}`
}

function handlePortalNoAccess() {
  fetch('/auth/v1/logout', {
    method: 'GET',
    credentials: 'include',
  })
    .then(() => {
      window.location.replace(`${window.UBO_UAM_LANDING_PAGE}?error_code=403`)
    })
    .catch((error) => {
      // Blocked by CORS if not connected to VPN
      window.location.replace(`${window.UBO_UAM_LANDING_PAGE}?error_code=403`)
      console.error(error)
    })
}

function handleRefresh(res: AxiosResponse<any, any>) {
  if (res.status !== 200) {
    // we have some issue that refresh gets 401, this need to fresh the page to get new token.
    // If the user session is expired, this refresh action  will redirect to login page
    window.location.reload()
  }
}

// Get permission without the need to get the current user in component
function hasPermission(
  permissionName: string,
  operatorEnvs: string[] = [],
): boolean {
  const { perms } = getCurrentUser()
  return getPermission(perms, permissionName, operatorEnvs)
}

export {
  PORTAL_ID,
  IAM_ENV,
  getCurrentUser,
  handleFetchResponse,
  parseVersion,
  handlePortalNoAccess,
  mapPortals,
  getPortal,
  handleRefresh,
  getConfig,
  hasPermission,
}
