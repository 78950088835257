import React from 'react'
import UBOThemeProvider from 'ui/src/mui/UBOThemeProvider'
import { Env, IAM_ENV, PlatformAppProps } from 'platform'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { TopBar } from 'ui'

const getHomeUrl = (env: Env) => {
  switch (env) {
    case Env.QAT:
      return IAM_ENV.QAT
    case Env.PREPROD:
      return IAM_ENV.PREPROD
    case Env.STG:
      return IAM_ENV.STG
    case Env.INTG:
      return IAM_ENV.STG
    default:
      return IAM_ENV.PROD
  }
}

export default function Root({ props }: { props: PlatformAppProps }) {
  const {
    userName,
    logOutURL,
    portalId,
    portals,
    nonce,
    env,
    enableTimezone = false,
    enableMultiLng = false,
    newFont = false,
  } = props
  const cache = createCache({
    key: 'css',
    nonce,
    prepend: true,
  })
  let homeUrl = IAM_ENV.PROD
  if (env) {
    homeUrl = `${getHomeUrl(env)}/?redirectTo=dashboard`
  }
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <CacheProvider value={cache}>
        <UBOThemeProvider>
          <TopBar
            homeUrl={homeUrl}
            userName={userName}
            logOutURL={logOutURL}
            portalId={portalId}
            portals={portals}
            enableTimezone={enableTimezone}
            enableMultiLng={enableMultiLng}
            newFont={newFont || false}
          />
        </UBOThemeProvider>
      </CacheProvider>
    </React.Suspense>
  )
}
